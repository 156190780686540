// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-default-js": () => import("../src/templates/Default.js" /* webpackChunkName: "component---src-templates-default-js" */),
  "component---src-templates-careers-js": () => import("../src/templates/Careers.js" /* webpackChunkName: "component---src-templates-careers-js" */),
  "component---src-templates-contact-page-js": () => import("../src/templates/ContactPage.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-archive-leaders-js": () => import("../src/templates/ArchiveLeaders.js" /* webpackChunkName: "component---src-templates-archive-leaders-js" */),
  "component---src-templates-about-page-js": () => import("../src/templates/AboutPage.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-capabilities-page-js": () => import("../src/templates/CapabilitiesPage.js" /* webpackChunkName: "component---src-templates-capabilities-page-js" */),
  "component---src-templates-archive-projects-js": () => import("../src/templates/ArchiveProjects.js" /* webpackChunkName: "component---src-templates-archive-projects-js" */),
  "component---src-templates-home-page-js": () => import("../src/templates/HomePage.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-single-project-js": () => import("../src/templates/SingleProject.js" /* webpackChunkName: "component---src-templates-single-project-js" */),
  "component---src-templates-how-we-operate-js": () => import("../src/templates/HowWeOperate.js" /* webpackChunkName: "component---src-templates-how-we-operate-js" */),
  "component---src-templates-single-leader-js": () => import("../src/templates/SingleLeader.js" /* webpackChunkName: "component---src-templates-single-leader-js" */),
  "component---src-pages-404-js": () => import("../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */)
}

